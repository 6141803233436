
.Biblioteca {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 120px;
  margin-top: 20px;
  min-width: 340px;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #707070;
  color: white;
  padding: 10px;
  border-radius: 10px;
  width: 20%;
  height: 100px;
  text-align: center;

}
.icon{
  font-size: 60px;
}

.item:hover {
  cursor: pointer;
}

.item span {
  margin-top: 10px;
  font-size: 20px;
  padding: 10px;
  font-size: 22px;
}

/* Estilos para dispositivos móveis */
@media (max-width: 768px) {
  .geralBiblioteca{
    display: flex;
    width: 100%;
    justify-content: center;
   ;
  }
  .Biblioteca {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 120px;
    margin-top: -10px;
  }
  .item {
    background-color: #707070;
    color: white;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 10px;
    min-height: 60px;
    max-height: 65px;
    width: 150px;
    text-align: center;
  
  }

  .item {
      width: 100%;
      height: auto;
  }
  .icon{
    font-size: 25px;
  }


  .item span {
      font-size: 16px;
      padding: 5px;
  }
}

@media (max-width: 480px) {
  .geralBiblioteca{
    display: flex;
    width: 100%;
   ;
  }
  .item {
    background-color: #707070;
    color: white;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 10px;
    height: 60px;
    width: 150px;
    text-align: center;
    
  
  }
  
  .Biblioteca {
    display: grid;
    grid-template-columns: auto auto;  
    justify-content: space-between;
    min-width: 360px;
    gap: 10px;

  }
  .icon{
    font-size: 30px;
  }

  .item span {
      font-size: 14px;
  }
}

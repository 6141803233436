
.Banner >div > img {
    height: 200px;
    width: 100%;
    top: 0px;
    left: 0px;
    opacity: 1;
}

.Banner {
    display: flex;
    padding-bottom: 10px;  
    margin-top: 10px; 
    margin-bottom: -20px;
    justify-content: space-between;


}@media (max-width: 768px) {
    .Banner {
        flex-direction: row; /* Empilha os itens verticalmente em telas menores */
        padding: 10px; /* Ajusta o padding para telas menores */
    }
    .Banner >div > img {
        height: 100px;
        width: 100%;
        top: 0px;
        left: 0px;
        opacity: 1;
    }

    .Lateral,
    .Writer {
        flex: none; /* Remove a flexibilidade em telas menores */
        max-width: 100%; /* Garante que as imagens não ultrapassem a largura do contêiner */
        margin-bottom: 10px; /* Adiciona espaçamento entre as imagens */
        height: 100px;
    }

    .Writer {
        margin-bottom: 0; /* Remove o espaçamento inferior para a última imagem */
    }
}

@media (max-width: 480px) {
    .Banner {
        padding: 1px; /* Reduz o padding para telas muito pequenas */
    }
    .Banner >div > img {
        height: 70px;
        width: 100%;
         opacity: 1;
    }


    .Lateral img,
    .Writer img {
        max-width: 100%; /* Garante que as imagens se ajustem bem em telas muito pequenas */
        overflow: hidden;
        height: 10px;
    }
}
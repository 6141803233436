@font-face {
    font-family: 'louis_gerorge_cafe';
    src: url(../assets/Louis\ George\ Cafe.ttf);
}


.MenuSuperior {
    font-family: louis_gerorge_cafe;
}
.menuIcon {
    display: none

}

.SuperiorItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.SuperiorItem>img {
    width: 230px;
}

.menu {
    font-weight: 100;
    font-size: 22px;
    cursor: pointer;
    padding: 2%;
    display: flex;
    justify-content: space-between;
    margin-right: 10px;
}

.menu>span {
    padding: 10px;
}


.linha {
    width: 100%;
    border: none;
    border-top: 1px solid #979797;
    /* Estilo da linha */

}

@media (max-width: 768px) {
    .menu {
        font-size: 18px;
        /* Ajuste o tamanho da fonte para telas menores */
        padding: 1%;
        flex-direction: column;
        /* Muda a direção do layout para coluna em telas menores */
        display: none;
    }

    .menuIcon {
        font-size: 20px;
        display: block;

    }

    .SuperiorItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 360px;

    }

    .SuperiorItem>img {
        width: 150px;
    }

    .menu>span {
        padding: 8px;
        /* Ajuste o padding para mais espaço em telas menores */
    }
}

@media (max-width: 480px) {
    .menu {
        font-size: 16px;
        /* Ajuste ainda mais o tamanho da fonte para telas muito pequenas */
    }
}
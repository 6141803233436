.BuscarLaudo {
    background-color: #fff;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid #979797;
    margin-top:30px ;
}
.inputNumero {
    width: 300px;
    color: rgb(160, 160, 160,.90);
    font-weight: Light Italic;
    font-size: 20px;
    height: 30px;
    opacity: 1;
    text-align: left;
    padding-left: 10px;
}
.verificar{
    width: 129px;
    color:   solid #979797;
    font-weight: Medium Italic;
    font-size: 20px;
    height: 30px;
    opacity: 1;
    text-align: center;
    margin-left: 10px;
    margin-top: 10px;
}

.containerBusca{
    padding: 10px 10px 20px 10px;

    
}

input[type="checkbox"] {
    margin: 10px; /* Espaçamento ao redor do checkbox */
    width: 18px; /* Largura do checkbox */
    height: 18px; /* Altura do checkbox */
    accent-color: #CE1827; /* Cor de destaque do checkbox no caso de navegadores que suportam */
}
label {
    /* Estilos para todos os labels */
    font-size: 17px;
       
}
.iconVisualizar{
    font-size: 30px;
}
h2{
    
    padding: 10px 10px 10px 10px;
}
p{
    font-size: 15px;
    padding: 10px 20px 5px 35px;
}

.gridLaudo{
    font-size: 18px;
    border: 1px rgb(160, 160, 160);
    
    
}

/* Criação do layout do grid */

.gridContainer {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    gap: 5px;
    width: 100%;
  }
  
  .gridLaudoHeader, .gridLaudo {
    display: contents;
  }
  
  .gridLaudoHeader span, .gridLaudo span {
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid #ddd;
    background-color: #f0f0f0;
  }
  
  .gridLaudo {
    background-color: #ffffff;
  }
  
  .gridLaudo span {
    background-color: #ffffff;
  }

  
  .visualisarLaudo :hover{
    cursor: pointer;
  }
  

  @media screen and (max-width: 768px) {
    .gridContainer {
        grid-template-columns: repeat(2, 1fr);
    }

    .inputNumero {
        width: 100%;
    }

    .verificar {
        width: 100%;
        margin: 10px 0 0 0;
    }

    p {
        padding: 10px 20px;
    }
}

@media screen and (max-width: 480px) {
  .BuscarLaudo {

    margin-top:60px ;
}
    .gridContainer {
      display: grid;
      grid-template-columns: auto auto auto auto auto;
      gap: 5px;
      width: 100%;
    }
    .gridLaudo, .gridLaudoHeader {
      font-size: 12px;
    }

    .inputNumero {
        font-size: 18px;
        max-width: 330px;
    }

    .verificar {
        font-size: 18px;
        width: 100px;
    }
    .iconVisualizar{
        font-size: 20px;
    }

    label {
        font-size: 14px;
    }

    h2 {
        font-size: 20px;
        padding: 10px;
    }

    p {
        font-size: 14px;
        padding: 10px;
    }
    .descPro{
      display: none;
    }
   
}
.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}
  

/* Estilização da barra superior */
.top-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: #3498db; /* Cor de fundo */
    color: white; /* Cor do texto */
    display: flex;
    align-items: center;
    justify-content: center; /* Espaço entre os elementos */
    padding: 0 20px; /* Espaçamento interno */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Sombra para destacar */
    z-index: 1000; /* Garante que a barra esteja acima de outros elementos */
}

/* Estilização dos botões de ícones */
.icon-button {
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    padding: 20px;
}


.certificado-container {
    height: 752px;
    width: 595px;
    margin: 10px;
    font-family: 'Times New Roman', Times, serif !important;
    color: #333;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.certificado-header,
.certificado-body {
    margin-bottom: 1px;
}

.certificado-footer {
    margin-bottom: 1px;
}

.header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.date {
    font-weight: bold;
}

h1 {
    text-align: center;
}

.header-row p {
    margin: 1px 0;
}

.date {
    text-align: right;
}

.header-table {
    width: 20%;
    margin-top: 5px;
    border-collapse: collapse;
    font-weight: bold;
}

.serial-table {
    width: 50%;
    margin-top: 5px;
    border-collapse: collapse;
}

.header-table td,
.serial-table th,
.serial-table td {
    border: 1px solid #ccc;
    padding: 5px;
    text-align: left;
}

.certificado-body p,
.certificado-footer p {
    margin: 5px 0;
}

.certificado-body table {
    width: 98%;
    border-collapse: collapse;
    margin-top: 10px;
}

.certificado-body th,
.certificado-body td {
    border: 1px solid #ccc;
    padding: 5px;
    text-align: center;
}

.certificado-footer h3 {
    margin-top: 10px;
}

.certificado-footer ul {
    margin: 10px;
    padding-left: 20px;
}

.certificado-footer ul li {
    margin-bottom: 5px;
}

.logo>img {
    height: 80px;
    top: 0px;
    left: 0px;
    opacity: 1;
}

.carimboAssinatura {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-around;
    margin-bottom: 20px;
    margin-top: auto;
    /* Ensures it stays above the footer */
}

.carimbo>img {
    height: 120px;
}

.assinatura>img {
    height: 65px;
}

.rodape {
    background-color: #CD1626;
    display: flex;
    flex-direction: column;
    height: 60px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    color: white;
    text-align: center;
    justify-content: center;
}

.fr {
    font-family: 'Times New Roman', Times, serif;
    color: #333;
    text-align: left;
    font-size: 10px;
    padding-left: 10px;
}

p,
ul {
    text-align: justify;
    font-size: 11px;
}

h3 {
    font-size: 13px;
}

h1 {
    font-size: 17px;
}

table {
    font-size: 11px;
}

.page {
    background: rgb(204, 204, 204);
}

.page {
    background: white;
    display: block;
    margin: 65px auto;
    margin-bottom: 0.5cm;
    box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
}

.page[size="A4"] {
    width: 21cm;
    height: 29.7cm;
}

.page[size="A4"][layout="portrait"] {
    width: 29.7cm;
    height: 21cm;
}

@media print {

    body,
    .page {
        margin: 0;
        box-shadow: 0;
        font-size: 12px;
        transform: scale(1.1);
        transform-origin: top;
        margin: 0;
    }

    .page {
        width: 100%;
        height: 100%;
        box-shadow: none;
        margin: 0;
    }

    .page * {
        break-inside: avoid;
    }

    .print-button {
        display: none;
    }
    .top-bar{
        display: none;

    }
    .rodape {
        margin-top: 40px;
    }
}

@media (max-width: 480px) {
    .certificado-container {
        height: 100vh;
        width: 100vw;
    }

    p,
    ul {
        text-align: justify;
        font-size: 9px;
    }

    h3 {
        font-size: 11px;
    }

    h1 {
        font-size: 14px;
    }

    table {
        font-size: 10px;
    }

    .rodape {
        font-size: 8px;
        margin-top: 110px;
    }
    

    .fr {
        font-size: 7px;
        margin-left: 10px;
    }

    table {
        margin: 5px;
    }

    .carimboAssinatura {
        
        margin-top: auto;
    }

    .print-button {
        margin-left: 40px;
    }
}




